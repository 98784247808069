import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, ItemBox, Name, Person, MobilePersonWrapper } from './PersonsStyle';
import placeholder from '../../../../static/images/placeholder.jpg';
import SinglePersonCardSlider from '../../../Sliders/SinglePersonCardSlider/SinglePersonCardSlider';
import { PersonInterface } from '../../../../interfaces/page';
import { urlForImage } from '../../../../utils/urlForImage';
import useBrowserWidth from '../../../../hooks/useBrowserWidth';
import { TABLET_SCREEN_SIZE } from '../../../../styles/MediaQuery';

interface PersonsInterface {
    item: PersonInterface[];
}

const Persons: FC<PersonsInterface> = ({ item }) => {
    const { t } = useTranslation();
    const currentBrowserWidth = useBrowserWidth();

    const displayPersons = (): ReactNode =>
        item.map((person: PersonInterface, index: number) => (
            <Person key={person.title ?? index}>
                <ItemBox>
                    <img
                        src={
                            person.field_image?.length > 0
                                ? urlForImage(person.field_image[0]?.field_media_image)
                                : placeholder
                        }
                        alt={
                            person.field_image?.length > 0 && person.field_image[0]?.alt
                                ? person.field_image[0].alt
                                : 'person img'
                        }
                    />
                </ItemBox>
                <ItemBox>
                    {person?.title && <Name>{person.title}</Name>}
                    {person?.field_position && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: person.field_position,
                            }}
                        />
                    )}
                </ItemBox>
                <ItemBox>
                    {person?.field_phone && (
                        <p>
                            {t('phone')}: <a href={`tel:${person.field_phone}`}>{person.field_phone}</a>
                        </p>
                    )}
                    {person?.field_mobile && (
                        <p>
                            {t('mobile')}: <a href={`tel:${person.field_mobile}`}>{person.field_mobile}</a>
                        </p>
                    )}
                </ItemBox>
                <ItemBox>
                    {person?.field_email && (
                        <p>
                            Email: <a href={`mailto:${person.field_email}`}>{person.field_email}</a>
                        </p>
                    )}
                </ItemBox>
            </Person>
        ));

    const mobileViewChecker = (): ReactNode => {
        if (currentBrowserWidth > TABLET_SCREEN_SIZE) {
            return displayPersons();
        }

        return (
            <MobilePersonWrapper>
                <SinglePersonCardSlider persons={item} />
            </MobilePersonWrapper>
        );
    };

    return <Container>{mobileViewChecker()}</Container>;
};

export default Persons;
