import React, { FC, useState, useRef, useEffect, ReactNode } from 'react';
import { PersonInterface } from '../../../interfaces/page';
import {
    Slider,
    Slide,
    SlideLink,
    SlideBoldText,
    SlideText,
    BackIconWrapper,
    ForwardIconWrapper,
    ForwardIcon,
    BackIcon,
} from './SinglePersonCardSliderStyle';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import placeholder from '../../../static/images/placeholder.jpg';
import { urlForImage } from '../../../utils/urlForImage';

interface SinglePersonCardSlider {
    persons: PersonInterface[];
}

const SinglePersonCardSlider: FC<SinglePersonCardSlider> = ({ persons }) => {
    const { t } = useTranslation();
    const numberOfPhotos = persons.length;
    const [activePhoto, setActivePhoto] = useState(0);
    const [sliderHeight, setSliderHeight] = useState(0);
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const sliderRef = useRef();
    const activeSlide = useRef();
    const inactiveSlide = useRef();

    useEffect(() => {
        const changeSliderHeight = () => {
            if (activeSlide && activeSlide.current?.clientHeight) {
                let borderBottom = getComputedStyle(activeSlide.current);
                setSliderHeight(activeSlide.current.clientHeight + parseInt(borderBottom.borderBottomWidth));
            }
        };
        if (isFirstRender) {
            setTimeout(changeSliderHeight, 1000);
            setIsFirstRender(true);
        }
        changeSliderHeight();
    }, [activePhoto]);

    const displaySlider = (): ReactNode =>
        persons.map((person: PersonInterface, index: number) => (
            <Slide
                ref={index === activePhoto ? activeSlide : inactiveSlide}
                key={person.title ?? index}
                isActive={index === activePhoto}
                position={index < activePhoto}
            >
                <img
                    src={
                        person.field_image?.length > 0
                            ? urlForImage(person.field_image[0]?.field_media_image)
                            : placeholder
                    }
                    alt={
                        person.field_image?.length > 0 && person.field_image[0]?.alt
                            ? person.field_image[0].alt
                            : 'person img'
                    }
                />
                {person.title && <SlideBoldText>{person.title}</SlideBoldText>}
                {person.field_position && (
                    <SlideText as={'div'}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: person.field_position,
                            }}
                        />
                    </SlideText>
                )}
                {person.field_phone && (
                    <SlideText>
                        {t('phone')}: <SlideLink href={`tel:${person.field_phone}`}>{person.field_phone}</SlideLink>
                    </SlideText>
                )}
                {person.field_email && (
                    <SlideText>
                        Email: <SlideLink href={`mailto:${person.field_email}`}>{person.field_email}</SlideLink>
                    </SlideText>
                )}
            </Slide>
        ));

    const nextImage = (): void => {
        activePhoto < numberOfPhotos - 1 ? setActivePhoto((activePhoto) => ++activePhoto) : setActivePhoto(0);
    };
    const previousImage = (): void => {
        activePhoto === 0 ? setActivePhoto(numberOfPhotos - 1) : setActivePhoto((activePhoto) => --activePhoto);
    };

    useEffect(() => {
        let startX,
            distX,
            threshold = 150;

        const touchStart = (e: any): void => {
            let touchobj = e?.changedTouches[0];
            startX = touchobj.pageX;
        };

        const touchEnd = (e: any): void => {
            let touchobj = e?.changedTouches[0];
            distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
            if (Math.abs(distX) >= threshold) {
                if (distX < 0) {
                    nextImage();
                }
                if (distX > 0) {
                    previousImage();
                }
            }
        };
        sliderRef?.current?.addEventListener('touchstart', touchStart, { once: true });
        sliderRef?.current?.addEventListener('touchend', touchEnd, { once: true });

        return () => {
            sliderRef?.current?.removeEventListener('touchstart', touchStart);
            sliderRef?.current?.removeEventListener('touchend', touchStart);
        };
    }, [activePhoto]);

    return (
        <Slider sliderHeight={sliderHeight} ref={sliderRef}>
            {activePhoto !== 0 && (
                <BackIconWrapper onClick={previousImage}>
                    <BackIcon />
                </BackIconWrapper>
            )}
            {activePhoto !== numberOfPhotos - 1 && (
                <ForwardIconWrapper onClick={nextImage}>
                    <ForwardIcon />
                </ForwardIconWrapper>
            )}
            {displaySlider()}
        </Slider>
    );
};

export default SinglePersonCardSlider;
