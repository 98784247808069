import styled from 'styled-components';
import { deviceMax } from '../../../../styles/MediaQuery';
import { containerWidth, darkGreen, greyBorder, linkOnHover } from '../../../../styles/Variables';

export const Container = styled.div`
    margin-bottom: 2rem;
`;

export const MobilePersonWrapper = styled.div`
    display: flex;
`;

export const Person = styled.div`
    max-width: ${containerWidth};
    display: grid;
    grid-template-columns: 160px 1fr 1fr 1fr;
    height: 80px;
    margin: 0 auto;
    border-top: 1px solid ${greyBorder};

    &:last-child {
        border-bottom: 1px solid ${greyBorder};
    }

    a {
        text-decoration: none;
        color: ${darkGreen};

        :hover {
            color: ${linkOnHover};
            text-decoration: none;
        }
    }

    @media ${deviceMax.tabletXl} {
        width: calc(100% - 2em);
    }

    * {
        font-family: Arial, Helvetica, sans-serif;
    }
`;

export const ItemBox = styled.div`
    * {
        font-size: 0.875rem;
    }

    img {
        width: 140px;
        height: 80px;
        object-fit: cover;
    }
`;

export const Name = styled.p`
    font-weight: 700;
`;
