import styled from 'styled-components';
import { darkGreen, linkOnHover, greyBorder } from '../../../styles/Variables';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

interface SliderInterface {
    readonly sliderHeight: number;
}

export const Slider = styled.div<SliderInterface>`
    width: 90%;
    margin: 0 auto;
    position: relative;
    height: ${(props) => `${props.sliderHeight}px`};
    overflow: hidden;
`;

interface SlideInterface {
    readonly isActive: boolean;
    readonly position: boolean;
}

export const Slide = styled.div<SlideInterface>`
    position: absolute;
    transition: 0.3s;
    top: 0;
    left: ${(props) => {
        if (props.isActive) {
            return '0';
        } else if (props.position) {
            return '-110%';
        } else {
            return '110%';
        }
    }};
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${greyBorder};

    * {
        font-size: 0.875rem;
        font-family: Arial, Helvetica, sans-serif;
    }

    img {
        max-height: 300px;
        object-fit: cover;
    }
`;

export const SlideText = styled.p`
    margin-bottom: 1.5rem;
    margin-top: 0;

    &:last-child {
        margin-bottom: 0.75rem;
    }

    a {
        text-decoration: none;
        color: ${darkGreen};

        :hover {
            color: ${linkOnHover};
            text-decoration: none;
        }
    }
`;

export const SlideBoldText = styled.p`
    font-weight: 700;
    margin-block: 0.75rem;
`;

export const SlideLink = styled.a`
    text-decoration: none;
    color: ${darkGreen};

    :hover {
        color: ${linkOnHover};
        text-decoration: none;
    }
`;

export const BackIcon = styled(IoIosArrowBack)`
    font-size: 1.5em;
    fill: white;
`;

export const ForwardIcon = styled(IoIosArrowForward)`
    font-size: 1.5em;
    fill: white;
`;

export const IconWrapper = styled.div`
    width: 48px;
    height: 48px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    top: 40%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    z-index: 2;

    &:hover {
        opacity: 0.3;
    }
`;

export const BackIconWrapper = styled(IconWrapper)`
    left: 0;
`;

export const ForwardIconWrapper = styled(IconWrapper)`
    right: 0;
`;
